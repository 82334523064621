import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import usePostShare from '../hooks/usePostShare'
import { Author } from '../models/Author'
import { Post } from '../models/Post'
import { Taxonomy } from '../models/Taxonomy'
import { blogPagePathBuilder } from '../templates/BlogHomePage.context'
import htmlToReact from '../utils/htmlToReact'
import markdownToHtml from '../utils/markdownToHtml'
import notEmpty from '../utils/notEmpty'
import ShareButton from './ShareButton'
import SocialLinks, { Share } from './SocialLinks'

interface TitleLinkProps {
  short?: 'true' | 'false'
}

const title = css<TitleLinkProps>`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 30px;
    line-height: 36px;
  }

  ${({ short }) =>
    short &&
    css`
      font-size: 12px;
      line-height: 0px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 12px;
        line-height: 0px;
      }
    `}
`

const TitleLink = styled(Link)<TitleLinkProps>`
  ${title}
`

const TitleContainer = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px; 
  
`

interface TitleProps {
  short?: boolean
  href?: string
  children: React.ReactNode
}

const Title: React.FC<TitleProps> = ({ short, href, children }) => {
  const shortValue = useMemo(() => (short ? 'true' : undefined), [short])

  return (
    <TitleContainer>
      {href ? (
        <TitleLink to={href} short={shortValue}>
          {children}
        </TitleLink>
      ) : (
        children
      )}
    </TitleContainer>
  )
}

const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 19px;
  }
`

const Cover = styled.span`
  width: 100%;
  height: 224px;
  border-radius: 5px;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 438px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  word-break: break-word;
  white-space: pre-line;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.body};
    margin-bottom: 17px;
    display: inline-block;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }

  figure {
    max-width: 75%;
    margin: 0 auto 10px auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 0 auto 20px auto;
    }
  }

  img {
    max-width: 100%;
    border-radius: 5px;
  }

  figcaption {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: ${({ theme }) => theme.colors.body};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  ul {
    list-style-type: '- ';
    padding-left: 20px;
    display: grid;
  }
`

const ShareActionsContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 12px;
  }
`

const Item = styled.li`
  display: inline-flex;
`

const PrintButton = styled.button`
  display: inline-flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
`

const PrintIcon = styled.img`
  width: 13px;
  height: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 16px;
    height: 14px;
  }
`

interface ShareActionsProps {
  share: Share
}

const ShareActions: React.FC<ShareActionsProps> = ({ share }) => {
  const handlePrint = useCallback(() => window.print(), [])

  return (
    <ShareActionsContainer>
      <Item>
        <ShareButton share={share} />
      </Item>
      <Item>
        <PrintButton onClick={handlePrint}>
          <PrintIcon src="/images/Print.svg" alt="Imprimer" />
        </PrintButton>
      </Item>
    </ShareActionsContainer>
  )
}

interface TagsWrapperProps {
  short?: boolean
}

const TagsWrapper = styled.ul<TagsWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 8px;
  }

  ${({ short }) =>
    short &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 4px;
      }
    `}
`

interface TagLinkProps {
  short?: 'true' | 'false'
}

const TagLink = styled(Link)<TagLinkProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};

  &:before {
    content: '#';
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 19px;
  }

  ${({ short }) =>
    short === 'true' &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 12px;
        line-height: 14px;
      }
    `}
`

interface TagsProps {
  items: Taxonomy[]
  short?: boolean
}

const Tags: React.FC<TagsProps> = ({ items, short }) => (
  <TagsWrapper short={short}>
    {items.map((tag, index) => (
      <li key={index}>
        <TagLink
          short={short ? 'true' : undefined}
          to={blogPagePathBuilder(0, undefined, tag.slug)}
        >
          {tag.name}
        </TagLink>
      </li>
    ))}
  </TagsWrapper>
)

const InfosWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: right;
`

const Name = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
`

interface DateWrapperProps {
  short?: boolean
}

const DateContainer = styled.div<DateWrapperProps>`
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.body};

  ${({ short }) =>
    short &&
    css`
      font-size: 12px;
      line-height: 14px;
    `}
`

const Avatar = styled.span`
  width: 47px;
  height: 47px;
  border-radius: 100px;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }
`

interface DateDisplayProps {
  date: string
  short?: boolean
}

const DateDisplay: React.FC<DateDisplayProps> = ({ date: dateStr, short }) => {
  const date = useMemo(() => new Date(dateStr), [dateStr])

  return (
    <DateContainer short={short}>{date.toLocaleDateString()}</DateContainer>
  )
}

interface InfosProps {
  author?: Author | null
  date?: string | null
  short?: boolean
}

const Infos: React.FC<InfosProps> = ({ author, date, short }) => {
  const image = useMemo(() => {
    const imageData =
      author?.extra?.avatar?.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    }
    return undefined
  }, [])

  return (
    <InfosWrapper>
      <div>
        {!short && author && (
          <Name>
            {author.extra?.firstname} {author.extra?.lastname}
          </Name>
        )}
        {!short && date && <DateDisplay short={short} date={date} />}
      </div>
      {!short && author && image && (
        <Avatar>
          <GatsbyImage image={image} alt={author.extra?.avatar?.name ?? ''} />
        </Avatar>
      )}
    </InfosWrapper>
  )
}

const SocialLinksHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
`

const Container = styled.div`
  display: grid;

  // title
  & > *[class^='PostContent__TitleContainer'] {
    margin-top: 10px;
  }

  // tags
  & > *[class^='PostContent__TagsWrapper'] {
    margin-top: 4px;
  }

  // print
  & > *[class^='PostContent__ShareActionsContainer'] {
    justify-content: flex-end;
  }

  // content
  & > *[class^='PostContent__Content'] {
    margin-top: 4px;
  }

  // infos
  & > *[class^='PostContent__InfosWrapper'] {
    justify-content: flex-end;
  }

  // social
  & > *[class^='PostContent__ShareFooterContainer'] {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: auto min-content;
    grid-template-areas:
      'cover cover'
      'title print'
      'subtitle subtitle'
      'tags tags'
      'content content'
      'video video'
      'infos infos'
      'social social';

    & > *[class^='PostContent__Cover'] {
      grid-area: cover;
    }

    & > *[class^='PostContent__TitleContainer'] {
      grid-area: title;
      margin-top: 20px;
    }

    & > *[class^='PostContent__SubTitle'] {
      grid-area: subtitle;
    }

    & > *[class^='PostContent__TagsWrapper'] {
      margin-top: 8px;
      grid-area: tags;
    }

    & > *[class^='PostContent__ShareActionsContainer'] {
      grid-area: print;
      margin-top: 20px;
      margin-right: 20px;
    }

    & > *[class^='PostContent__Content'] {
      grid-area: content;
      margin-top: 30px;
    }

    & > *[class^='PostContent__InfosWrapper'] {
      grid-area: infos;
    }

    & > *[class^='PostContent__ShareFooterContainer'] {
      grid-area: social;
    }
  }
`

const ShareFooterContainer = styled.div``

interface ShareFooterProps {
  share: Share
}

const ShareFooter: React.FC<ShareFooterProps> = ({ share }) => (
  <ShareFooterContainer>
    <SocialLinksHeader>
      Si cet article vous a plu, n’oubliez pas de le partager :
    </SocialLinksHeader>
    <SocialLinks shadow share={share} />
  </ShareFooterContainer>
)

interface EmbedVideoProps {
  url: string
}

const EmbedVideo: React.FC<EmbedVideoProps> = ({ url }) => (
  <iframe
    width="560"
    height="315"
    src={url}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)

interface PostContentProps {
  item: Post
}

const PostContent: React.FC<PostContentProps> = ({ item }) => {
  const share = usePostShare(item)
  const content = useMemo(
    () => htmlToReact(markdownToHtml(item.content)),
    [item.content]
  )

  const image = useMemo(() => {
    const imageData = item.cover?.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    }
    return undefined
  }, [])

  return (
    <Container>
      {image && (
        <Cover>
          <GatsbyImage image={image} alt={item.cover?.name ?? ''} />
        </Cover>
      )}
      <Title>{item.title}</Title>
      <SubTitle>{item.subtitle}</SubTitle>
      {item.tags && <Tags items={item.tags.filter(notEmpty)} />}
      <ShareActions share={share} />
      <Content>{content}</Content>
      {item.video && <EmbedVideo url={item.video} />}
      <Infos author={item.author} date={item.published_at} />
      <ShareFooter share={share} />
    </Container>
  )
}

export default Object.assign(PostContent, {
  Title,
  SubTitle,
  ShareActions,
  Tags,
  Content,
  EmbedVideo,
  Infos,
  Name,
  Date: DateDisplay,
  ShareFooter,
})
